<script>
    import BaseButton from "@main/packages/BaseButton";
    import TheSlider from "@main/packages/TheSlider";
    import TheSvgIcon from "@main/packages/TheSvgIcon";
    import LinkWithAnalytics from "@/components/LinkWithAnalytics";

    export default {
        name: "StoreShop",
        components: { LinkWithAnalytics, TheSvgIcon, BaseButton, TheSlider },
        props: {
            shop: {
                type: Object,
                default: () => {},
            },
            sliderOptions: {
                type: Object,
                default: () => {},
            },
            icon: {
                type: String,
                validator: (v) => ["metro", "navigation"].includes(v),
            },
        },
        methods: {
            getImageUrl(index, isMobile = false) {
                const postfix = isMobile ? "--mobile" : "";
                return `/static/${this.$root.$_name}/store/${this.shop.type}/${
                    index + 1
                }${postfix}.jpg?v=13102021`;
            },
        },
    };
</script>

<template>
    <div class="store-shop">
        <div class="store-shop__inner">
            <div class="store-shop__main">
                <div class="store-shop__info">
                    <div class="store-shop__col">
                        <div class="store-shop__contact">
                            <img :src="`/static/${$root.$_name}/store/location.svg`" alt="" />
                            {{ shop.address }}
                        </div>
                        <div class="store-shop__contact">
                            <img :src="`/static/${$root.$_name}/store/call.svg`" alt="" />
                            <link-with-analytics
                                type="phone"
                                :text="shop.phone"
                            ></link-with-analytics>
                        </div>
                    </div>
                    <div class="store-shop__col">
                        <div class="store-shop__contact">
                            <img :src="`/static/${$root.$_name}/store/clock.svg`" alt="" />
                            Каждый день с 10:00 до 22:00
                        </div>
                    </div>
                </div>
                <div class="store-shop__gallery">
                    <the-slider
                        class="store-shop__slider"
                        :name-slider="`store-shop-${shop.type}`"
                        :is-pagination="sliderOptions.isPagination"
                        :is-bullets="sliderOptions.isBullets"
                        :is-arrow="sliderOptions.isArrow"
                        :arrow-position="sliderOptions.arrowPosition"
                        :bullets-options="sliderOptions.bulletsOptions"
                        :key="`store-shop-${shop.type}`"
                    >
                        <div slot="swiper-slide" class="gallery-slider__slide swiper-slide">
                            <picture>
                                <source
                                    media="(max-width: 450px)"
                                    :srcset="`/static/store/${shop.type}--mobile.jpg?v=181121`"
                                />
                                <img
                                    class="about-shop__img"
                                    :src="`/static/store/${shop.type}.jpg?v=181121`"
                                    alt=""
                                />
                            </picture>
                        </div>
                        <div
                            slot="swiper-slide"
                            class="gallery-slider__slide swiper-slide"
                            v-for="(_, index) in shop.slides"
                            :key="index"
                        >
                            <picture>
                                <source
                                    media="(max-width: 450px)"
                                    :srcset="getImageUrl(index, true)"
                                />
                                <img
                                    class="about-shop__img"
                                    :src="getImageUrl(index)"
                                    alt=""
                                />
                            </picture>
                        </div>
                    </the-slider>
                </div>
            </div>
            <div class="store-shop__side">
                <div class="store-shop__map" v-html="shop.iframe"></div>
                <div class="store-shop__routes">
                    <base-button
                        class="store-shop__route"
                        v-for="route in shop.routes"
                        :key="route.url"
                        size="lg"
                        tag="a"
                        :href="route.url"
                        target="_blank"
                    >
                        {{ route.title }}
                        <the-svg-icon :name="icon" :size-w="18" is-common></the-svg-icon>
                    </base-button>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .store-shop__inner {
        @include bp($bp-desktop-sm) {
            display: flex;
        }
    }

    .store-shop__main {
        @include bp($bp-desktop-sm) {
            flex-basis: calc(65% - 16px);
            max-width: calc(65% - 16px);
            width: 100%;
            margin-right: 16px;
        }
    }

    .store-shop__side {
        @include bp($bp-desktop-sm) {
            flex-basis: 35%;
            max-width: 35%;
        }
    }

    .store-shop__gallery {
        margin-bottom: 24px;
        @include bp($bp-desktop-sm) {
            margin-bottom: 0;
        }
    }

    .store-shop__slider {
        .c-slider__swiper {
            margin-bottom: 16px;
            border-radius: var(--new-store-content-radius);
        }

        .c-slider__scrollbar {
            margin-top: 16px;
            @include bp($bp-desktop-sm) {
                display: none;
            }
        }

        .c-slider__bottom-wrapper {
            display: none;
            @include bp($bp-desktop-sm) {
                display: flex;
                svg {
                    fill: var(--slider-arrow-store);
                }
            }
        }

        .c-slider__arrow--next {
            right: 16px;
        }

        .c-slider__arrow--prev {
            left: 16px;
        }
    }

    .gallery-slider__slide {
        position: relative;
        background-color: var(--new-store-content-background);
        border-radius: var(--new-store-content-radius);
        overflow: hidden;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            max-width: 100%;
        }
        &::before {
            content: "";
            display: block;
            padding-bottom: 66.66%;
        }
        @include bp($bp-desktop-sm) {
            img {
                max-height: 500px;
                object-fit: cover;
            }
        }
    }

    .store-shop__map {
        max-height: 400px;
        margin-bottom: 12px;
        background-color: var(--new-store-content-background);
        border-radius: var(--new-store-content-radius);
        overflow: hidden;
        iframe {
            display: block;
            width: 100%;
            max-width: 100%;
        }
        @include bp($bp-desktop-sm) {
            max-height: none;
            margin-bottom: 16px;
        }
    }

    .store-shop__info {
        margin-bottom: 12px;
        padding: 0 20px;
        color: #000;
        font-size: 18px;
        font-weight: 300;
        line-height: 24px;
        background-color: var(--new-store-content-background);
        border-radius: var(--new-store-content-radius);
        @include bp($bp-desktop-sm) {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            padding: 18px 0;
        }
    }

    .store-shop__col {
        padding: 16px 0;
        &:first-child {
            border-bottom: 1px solid var(--new-store-divider);
        }
        @include bp($bp-desktop-sm) {
            padding: 0 30px;
            &:first-child {
                flex-basis: 330px;
                max-width: 100%;
                border-bottom: none;
                border-right: 1px solid var(--new-store-divider);
            }
        }
    }

    .store-shop__contact {
        display: flex;
        align-items: center;
        color: #000;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        &:not(:last-child) {
            margin-bottom: 12px;
        }
        img {
            width: 24px;
            height: 24px;
            flex-shrink: 0;
            margin-right: 6px;
        }
        a {
            color: currentColor;
        }
        @include bp($bp-desktop-sm) {
            font-size: 18px;
            img {
                width: 30px;
                height: 30px;
            }
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }

    .store-shop__routes {
        a {
            &:not(:last-child) {
                margin-bottom: 12px;
            }
        }
        svg {
            margin-left: 5px;
            vertical-align: middle;
        }
        @include bp($bp-desktop-sm) {
            a {
                &:not(:last-child) {
                    margin-bottom: 16px;
                }
            }
        }
    }

    .store-shop__route {
        padding: 12px 5px;
        font-size: 14px;
        @include bp($bp-desktop-sm) {
            font-size: var(--btn-size--lg);
        }
    }
</style>
