<script>
    import TheSvgIcon from "@main/packages/TheSvgIcon";
    import StoreShop from "@/components/StoreShop";

    export default {
        name: "StoreShops",
        components: { TheSvgIcon, StoreShop },
        props: {
            sliderOptions: Object,
            slidesCount: Object,
            icon: String,
        },
        data() {
            return {
                selectedTabIndex: 0,
                newCities: {
                    msk: {
                        index: 0,
                        code: "msk",
                        name: "Москва",
                    },
                    spb: {
                        index: 1,
                        code: "spb",
                        name: "Санкт-Петербург",
                    },
                    ekb: {
                        index: 2,
                        code: "ekb",
                        name: "Екатеринбург",
                    },
                    krd: {
                        index: 3,
                        code: "krd",
                        name: "Краснодар",
                    },
                },
            };
        },
        computed: {
            cityName() {
                return Object.values(this.newCities).find(
                    (city) => city.index === this.selectedTabIndex
                ).name;
            },
            tabOffsetCompute() {
                return 100 * this.selectedTabIndex + "%";
            },
            shops() {
                return [
                    {
                        type: "msk",
                        iframe: '<iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A41a367aae849d125ee93d28a232574e4bf694adc968f2e2a9cf6530b2ed43725&amp;source=constructor" width="600" height="500" frameborder="0"></iframe>',
                        address: "Бутырский Вал, 32",
                        phone: "+7 495 646-10-96",
                        routes: [
                            { title: "Маршрут от Новослободской", url: "/5/" },
                            { title: "Маршрут от Белорусской", url: "/4/" },
                        ],
                        slides: this.slidesCount.msk,
                    },
                    {
                        type: "spb",
                        iframe: '<iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Ac6f9b787eeebe5ed6ea9c25a7ccad2e52657b10554778a34a65a58403f0a825d&amp;source=constructor" width="600" height="500" frameborder="0"></iframe>',
                        address: "Лиговский проспект, 3/9",
                        phone: "+7 812 407-35-98",
                        routes: [{ title: "Маршрут от Площади Восстания", url: "/7/" }],
                        slides: this.slidesCount.spb,
                    },
                    {
                        type: "ekb",
                        iframe: '<iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Addb23712cb2e365cdb6341786c0719c5a8602318dd4692abae978a44d43ac5e9&amp;source=constructor" width="600" height="500" frameborder="0"></iframe>',
                        address: "ул. Горького, 33А",
                        phone: "+7 343 363-33-16",
                        routes: [{ title: "Маршрут от м. Площадь 1905 года", url: "/11/" }],
                        slides: this.slidesCount.ekb,
                    },
                    {
                        type: "krd",
                        iframe: '<iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Ae7fedec2b8b4574d9634058e92244cb7a83f0d94e302e8f4cc6267a260b6ded7&amp;source=constructor" width="600" height="635" frameborder="0"></iframe>',
                        address: "ул. Леваневского, 187",
                        phone: "+7 861 21-12-861",
                        // routes: [{ title: "Маршрут от м. Площадь 1905 года", url: "/12/" }],
                        slides: this.slidesCount.krd,
                    },
                ];
            },
        },
        mounted() {
            if (location.hash) {
                const hash = location.hash.replace("#", "");
                this.selectedTabIndex = this.newCities[hash]?.index || 0;
            }
        },
        methods: {
            changeTabIndex(index) {
                this.selectedTabIndex = index;
                location.hash = Object.values(this.newCities)[index].code;
            },
            switchTab(direction) {
                if (direction === "prev") {
                    this.selectedTabIndex > 0
                        ? this.selectedTabIndex--
                        : (this.selectedTabIndex = 0);
                }
                if (direction === "next") {
                    this.selectedTabIndex < 3
                        ? this.selectedTabIndex++
                        : (this.selectedTabIndex = 3);
                }
            },
        },
    };
</script>

<template>
    <div class="store-shops js-store-shops">
        <div class="store-shops__tabs">
            <div class="store-shops__controls">
                <span
                    class="store-shops__controls-progress"
                    :style="{ transform: `translateX(${tabOffsetCompute})` }"
                ></span>
                <button
                    v-for="city in Object.values(newCities)"
                    class="store-shops__trigger outline"
                    :class="{ selected: selectedTabIndex === city.index }"
                    :key="city.index"
                    @click="changeTabIndex(city.index)"
                >
                    {{ city.name }}
                </button>
            </div>
            <div class="store-shops__city">
                {{ cityName }}
                <div class="store-shops__mobile-controls">
                    <button
                        class="store-shops__mobile-control control-left"
                        :disabled="selectedTabIndex === 0"
                        @click="switchTab('prev')"
                    >
                        <the-svg-icon name="arrow" :size-w="24"></the-svg-icon>
                    </button>

                    <button
                        class="store-shops__mobile-control control-right"
                        :disabled="selectedTabIndex === 3"
                        @click="switchTab('next')"
                    >
                        <the-svg-icon name="arrow" :size-w="24"></the-svg-icon>
                    </button>
                </div>
            </div>

            <div class="store-shops__content">
                <div class="store-shops__tab">
                    <store-shop
                        :id="selectedTabIndex"
                        :shop="shops[selectedTabIndex]"
                        :slider-options="sliderOptions"
                        :icon="icon"
                    ></store-shop>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .store-shops {
        padding-top: 16px;
        @include bp($bp-desktop-sm) {
            padding-top: 32px;
        }
    }

    .store-shops__tabs {
        margin-bottom: 20px;
        border: var(--new-store-content-border);
        border-color: var(--new-store-content-border-color);
        border-radius: var(--new-store-content-radius);
        box-shadow: var(--new-store-content-shadow);
        @include bp($bp-desktop-sm) {
            border: none;
            box-shadow: none;
        }
    }

    .store-shops__controls {
        display: none;
        @include bp($bp-desktop-sm) {
            position: relative;
            display: flex;
            margin-bottom: 16px;
            background-color: var(--new-store-button-fill);
            border-radius: var(--new-store-button-radius);
            overflow: hidden;
        }
    }

    .store-shops__controls-progress {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 25%;
        background: var(--new-store-button-fill--selected);
        border-radius: inherit;
        pointer-events: none;
        transition: transform 0.2s ease-in;
    }

    .store-shops__mobile-controls {
        position: absolute;
        top: 20px;
        left: 0;
        right: 0;
    }

    .store-shops__mobile-control {
        position: absolute;
        width: 24px;
        height: 24px;
        -webkit-tap-highlight-color: transparent;
        svg {
            transition: opacity 0.2s ease-in;
            fill: var(--slider-arrow-store);
        }
        &:disabled {
            pointer-events: none;
            svg {
                opacity: 0.3;
            }
        }
        &.control-left {
            left: 7px;
        }
        &.control-right {
            right: 7px;
            transform: scale(-1);
        }
    }

    .store-shops__city {
        position: relative;
        padding-top: 16px;
        color: var(--new-store-city-title-color--mobile);
        font-size: 22px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;
        @include bp($bp-desktop-sm) {
            display: none;
        }
    }

    .store-shops__trigger {
        @include bp($bp-desktop-sm) {
            position: relative;
            z-index: 2;
            flex-basis: 33.333334%;
            max-width: 33.333334%;
            padding: 30px 15px 28px;
            font-size: 24px;
            font-weight: 500;
            line-height: 32px;
            border-radius: var(--new-store-button-radius);
            transition: color 0.2s ease-in;
            &:hover:not(.selected) {
                color: var(--new-store-button-color--hover);
            }
            &.selected {
                color: #fff;
            }
        }
    }

    .store-shops__content {
        padding: 16px 12px 12px;
        border: none;
        border-radius: var(--new-store-content-radius);
        box-shadow: none;
        @include bp($bp-desktop-sm) {
            padding: 40px 40px 24px;
            border: var(--new-store-content-border);
            box-shadow: var(--new-store-content-shadow);
        }
    }
</style>
