<script>
    import BaseButton from "@main/packages/BaseButton";
    export default {
        name: "StoreDescription",
        components: { BaseButton },
        props: {
            catalogButtonText: String,
            catalogButtonVariant: {
                type: String,
                default: "v1",
            },
        },
        methods: {
            goToCatalog() {
                if (this.$root.$_name === "travel-blue") {
                    this.$router.push({ name: "Catalog" });
                } else {
                    this.$router.push({ name: "Home", hash: "#catalog" });
                }
            },
        },
    };
</script>

<template>
    <section class="store-description">
        <header class="store-description__header">
            <h2 class="store__title"><slot name="description-title"></slot></h2>
            <div class="store__subtitle">
                <slot name="description-subtitle"></slot>
            </div>
        </header>

        <div class="store-description__text-wrapper">
            <div class="l-container">
                <div class="store-description__text">
                    <slot name="description-text"></slot>
                </div>
            </div>
            <div class="store-description__decors">
                <div class="store-description__decor decor-left">
                    <picture>
                        <source
                            media="(max-width: 991px)"
                            :srcset="`/static/${$root.$_name}/store/decor-left--mobile.png?v=151121`"
                        />
                        <img
                            :src="`/static/${$root.$_name}/store/decor-left.png?v=151121`"
                            alt=""
                        />
                    </picture>
                </div>
                <div class="store-description__decor decor-right">
                    <picture>
                        <source
                            media="(max-width: 991px)"
                            :srcset="`/static/${$root.$_name}/store/decor-right--mobile.png`"
                        />
                        <img :src="`/static/${$root.$_name}/store/decor-right.png`" alt="" />
                    </picture>
                </div>
            </div>

            <base-button
                class="store-description__button"
                :variant="catalogButtonVariant"
                size="lg"
                @click="goToCatalog"
            >
                {{ catalogButtonText }}
            </base-button>
        </div>
    </section>
</template>

<style lang="scss">
    .store-description {
        padding: 20px 0 80px;
        @include bp($bp-desktop-sm) {
            padding: 40px 0 120px;
            overflow: hidden;
        }
    }

    .store-description__header {
        padding: 0 16px;
    }

    .store-description__text-wrapper {
        position: relative;
        margin: 16px 16px 0;
        padding: 16px 0 120px;
        background-color: var(--new-store-content-background);
        border-radius: var(--new-store-content-radius);
        @include bp($bp-desktop-sm) {
            min-height: 224px;
            margin: 16px 0 0;
            padding: 32px 0;
            border-radius: 0;
        }
    }

    .store-description__text {
        position: relative;
        z-index: 5;
        color: var(--primary-text);
        font-size: 14px;
        font-weight: 300;
        line-height: 28px;
        text-align: center;
        @include bp($bp-desktop-sm) {
            max-width: 660px;
            margin: 0 auto 16px;
            font-size: 16px;
            line-height: 34px;
            span {
                display: block;
            }
        }
    }

    .store-description__button {
        position: absolute;
        z-index: 5;
        left: 0;
        bottom: -110px;
        width: 100%;
        @include bp($bp-desktop-sm) {
            position: static;
            max-width: 320px;
            width: 100%;
            margin: 0 auto;
        }
    }

    .store-description__decors {
        position: relative;
        @include bp($bp-desktop-sm) {
            position: absolute;
            top: 32px;
            left: 0;
            right: 0;
            max-width: 1440px;
            margin: 0 auto;
            pointer-events: none;
        }
        @include bp($bp-desktop-md) {
            top: -80px;
        }
    }

    .store-description__decor {
        position: absolute;
        top: 0;
        width: 200px;
        &.decor-left {
            z-index: 2;
            left: -16px;
        }
        &.decor-right {
            right: -16px;
        }
        @include bp($bp-desktop-sm) {
            width: 39%;
            &.decor-left {
                left: -30px;
            }
            &.decor-right {
                right: -30px;
            }
        }
    }
</style>
