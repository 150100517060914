<script>
    import StoreShops from "@/components/StoreShops";
    import StoreDescription from "@/components/StoreDescription";

    export default {
        name: "StoreContent",
        components: {
            StoreDescription,
            StoreShops,
        },
        props: {
            catalogButtonText: String,
            catalogButtonVariant: String,
            sliderOptions: Object,
            icon: String,
            slidesCount: Object,
        },
    };
</script>

<template>
    <div class="store">
        <div class="l-container">
            <h1 class="store__title">Наши магазины</h1>
            <div class="store__subtitle">
                <slot name="subtitle"></slot>
            </div>
            <store-shops
                :slider-options="sliderOptions"
                :slides-count="slidesCount"
                :icon="icon"
            ></store-shops>
        </div>
        <store-description
            :catalog-button-text="catalogButtonText"
            :catalog-button-variant="catalogButtonVariant"
        >
            <template #description-title>
                <slot name="description-title"></slot>
            </template>
            <template #description-subtitle>
                <slot name="description-subtitle"></slot>
            </template>
            <template #description-text>
                <slot name="description-text"></slot>
            </template>
        </store-description>
    </div>
</template>

<style lang="scss">
    .store {
        padding: 32px 0 120px;
        @include bp($bp-desktop-sm) {
            padding-bottom: 30px;
        }
    }

    .store__title {
        margin-bottom: 12px;
        font-size: 26px;
        font-weight: var(--typography-heading-weight);
        line-height: 32px;
        text-align: center;
        @include bp($bp-desktop-sm) {
            margin-bottom: 16px;
            font-size: 36px;
            line-height: 40px;
        }
    }

    .store__subtitle {
        color: #182028;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
        span {
            display: block;
        }
        div {
            display: inline;
        }
        a {
            color: #0069ff;
        }
        @include bp($bp-desktop-sm) {
            max-width: 1100px;
            margin: 0 auto;
            font-size: 18px;
            line-height: 34px;
            span {
                display: inline-block;
            }
            div {
                display: block;
            }
            a {
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
</style>
