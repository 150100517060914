<script>
    import { metaInfo } from "@/core/MetaInfo";
    import StoreContent from "@/components/StoreContent";

    export default {
        name: "Store",
        components: { StoreContent },
        computed: {
            sliderOptions() {
                return {
                    isPagination: false,
                    isBullets: true,
                    bulletsOptions: {
                        type: "dot",
                        dynamicBullets: false,
                        clickable: true,
                    },
                    isArrow: true,
                    arrowPosition: "center",
                };
            },
        },
        metaInfo() {
            return metaInfo.getMeta("store");
        },
    };
</script>

<template>
    <store-content
        catalog-button-text="Выбрать товары"
        :slider-options="sliderOptions"
        :slides-count="{ msk: 4, spb: 4, ekb: 4, krd: 5 }"
        icon="navigation"
    >
        <template #subtitle>
            Приходите в магазины Madrobots. Наши консультанты с удовольствием ответят на ваши
            вопросы.
        </template>
        <template #description-title>Travel Blue и Madrobots</template>
        <template #description-subtitle>
            <a href="https://madrobots.ru/" target="_blank">Madrobots.ru</a> — оператор
            официального интернет-магазина Travel Blue в России
        </template>
        <template #description-text>
            Приходите в&nbsp;гости! Вы&nbsp;можете заранее сделать заказ, ближе познакомиться
            с&nbsp;товарами в&nbsp;магазине&nbsp;&mdash; и&nbsp;приобрести подходящие.
        </template>
    </store-content>
</template>

<style lang="scss">
    .store {
        .store__subtitle {
            font-size: 14px;
            line-height: 28px;
            font-weight: 400;
            @include bp($bp-desktop-sm) {
                font-size: 16px;
                line-height: 34px;
                font-weight: 500;
            }
        }
        .store-shop__slider {
            .c-slider__arrow {
                display: none;
                @include bp($bp-desktop-sm) {
                    display: flex;
                }
            }
            .c-slider__bottom-wrapper {
                display: flex;
            }
        }
        .store-shops__content {
            background-color: var(--service-background-color);
        }
        .store-shop__info {
            background-color: #fff;
            box-shadow: 0px 16px 32px rgba(10, 70, 110, 0.1);
        }
        .store-shops__tabs {
            background-color: var(--service-background-color);
            @include bp($bp-desktop-sm) {
                background-color: #fff;
            }
        }
    }
    .store-description {
        padding-bottom: 100px;
    }
    .store-description__button {
        bottom: -140px;
    }
</style>
